import { CounterSalesApi } from '@/api';
import { Message } from '@/utils';
import React, { createContext, useState, useEffect } from 'react'

const initialState = {
    cartData: [],
};

export const CartContext = createContext<any>(initialState);

export const CartProvider = ({ children }) => {
    const [cartData, setCartData] = useState<any>(initialState.cartData);

    useEffect(() => {
        loadCart();
    }, []);

    useEffect(() => {
       // if (cartData?.length) {
            localStorage.setItem('cartData', JSON.stringify(cartData));
       // }


        // if(!!cartData?.length){
        //     loadData()
        // }
    }, [JSON.stringify(cartData), cartData?.length]);

    const loadCart = () => {
        const items = localStorage.getItem('cartData');
        if (items && items != '[]') {
            setCartData(JSON.parse(items));
        }
    };

    // const loadData = (): Promise<any> => {
    //     return new Promise((resolve, reject) => {
    //         CounterSalesApi.getById(cartData[0].sale_id)
    //             .then(res => {
    //                 if (res.data.data.status === "PAID") {
    //                     deleteAllCartItem()
    //                     // navigate("/")
    //                 }
    //             })
    //             .catch(err => {
    //                 showErrorMessage('Something is wrong! Please try again.');
    //                 reject(err);
    //             });
    //     });
    // }

    const addToCart = (cartItem) => {
        setCartData((prevState) => {
            return [...prevState, cartItem];
        });
    };

    const updateCartItem = (entityIndex, cartItem) => {
        setCartData(prevState => {
            prevState[entityIndex] = { ...prevState[entityIndex], ...cartItem }
            return [...prevState];
        });
    };

    const deleteCartItem = (cartIndex) => {
        const filteredItems = cartData.filter((item, index) => index !== cartIndex);
        setCartData(filteredItems);
    };

    const deleteAllCartItem = () => {
        setCartData(initialState.cartData);
    };

    const contextValue = {
        cartData, addToCart, updateCartItem, deleteCartItem, deleteAllCartItem
    };

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
