import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.ETICKET_SERVER_PREFIX}/report`
const endpoints = {
    getExampleReport: () => `${RESOURCE_ENDPOINT}/example-report`,
    getDateWiseTicketSellReportList: () => `${RESOURCE_ENDPOINT}/datewise-ticket-sell`,
    getOnlineTicketSellReportList: () => `${RESOURCE_ENDPOINT}/online-ticket-sell`,
    getStaffWiseTicketSellReportList: () => `${RESOURCE_ENDPOINT}/staffwise-ticket-sell`,
    getStaffWiseComTicketSellReportList: () => `${RESOURCE_ENDPOINT}/staffwise-com-ticket-sell`,
    getInvoiceWiseTicketSummaryReportList: () => `${RESOURCE_ENDPOINT}/invoicewise-ticket-summary`,
    getProgramWiseTicketSellReportList: () => `${RESOURCE_ENDPOINT}/programwise-ticket-sell`,
    getProgramWiseComTicketSellReportList: () => `${RESOURCE_ENDPOINT}/programwise-com-ticket-sell`,
    getTicketCheckoutLogReportList: () => `${RESOURCE_ENDPOINT}/ticket-checkout-log`,
    getRequestTicketReportList: () => `${RESOURCE_ENDPOINT}/request-ticket`,
    getDeletedTicketReportList: () => `${RESOURCE_ENDPOINT}/deleted-ticket`,
}

export default class ReportEticketApi {
    public getExampleReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getExampleReport();
        return HttpService.get(url, params, headers);
    };

    public getDateWiseTicketSellReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDateWiseTicketSellReportList();
        return HttpService.get(url, params, headers);
    };

    public getOnlineTicketSellReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getOnlineTicketSellReportList();
        return HttpService.get(url, params, headers);
    };

    public getStaffWiseTicketSellReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getStaffWiseTicketSellReportList();
        return HttpService.get(url, params, headers);
    };

    public getStaffWiseComTicketSellReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getStaffWiseComTicketSellReportList();
        return HttpService.get(url, params, headers);
    };

    public getInvoiceWiseTicketSummaryReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getInvoiceWiseTicketSummaryReportList();
        return HttpService.get(url, params, headers);
    };

    public getProgramWiseTicketSellReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getProgramWiseTicketSellReportList();
        return HttpService.get(url, params, headers);
    };

    public getProgramWiseComTicketSellReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getProgramWiseComTicketSellReportList();
        return HttpService.get(url, params, headers);
    };

    public getTicketCheckoutLogReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTicketCheckoutLogReportList();
        return HttpService.get(url, params, headers);
    };

    public getRequestTicketReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRequestTicketReportList();
        return HttpService.get(url, params, headers);
    };

    public getDeletedTicketReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDeletedTicketReportList();
        return HttpService.get(url, params, headers);
    };
}
