export enum StatusEnum {
    Active = 1,
    Inactive = 0,
}

export enum TicketStatusEnum {
    'Available' = "AVAILABLE",
    "Reserved" = "RESERVED",
}

export enum PaymentTypeEnum {
    'bKash' = "BKASH",
    "Nagad" = "NAGAD",
    "Visa Card" = "VISA_CARD",
    "Master Card" = "MASTER_CARD",
}

export enum ApprovalStatusEnum {
    Pending = 0,
    Approved = 1,
}

export enum AccessStatusEnum {
    'Access' = 1,
    'Not Access' = 0,
}
