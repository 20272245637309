export default class TreeUtils {

    public navTreeIteration = (Arr, pid, idName, pidName, name) => {
        let result:any = [];
        let temp = [];
        for (let i = 0; i < Arr.length; i++) {
            if (parseInt(Arr[i][pidName]) === parseInt(pid)) {
                const obj = { 'label': Arr[i][name], 'value': Arr[i][idName], 'title': Arr[i][name],'key':Arr[i][idName], 'children': []}
                temp = this.navTreeIteration(Arr, Arr[i][idName],idName,pidName,name);
                if (temp.length > 0) {
                    obj.children = temp
                }
                result.push(obj)
            }
        }
        return result;
    }

    public navTreeStrIteration = (Arr, pid, idName, pidName, name) => {
        let result:any = [];
        let temp = [];
        for (let i = 0; i < Arr.length; i++) {
            if (Arr[i][pidName] === pid) {
                const obj = { 'label': Arr[i][name], 'value': Arr[i][idName], 'title': Arr[i][name],'key':Arr[i][idName], 'children': []}
                temp = this.navTreeStrIteration(Arr, Arr[i][idName],idName,pidName,name);
                if (temp.length > 0) {
                    obj.children = temp
                }
                result.push(obj)
            }
        }
        return result;
    }

    public tableTreeIteration = (Arr, options) => {
        let result:any = [];
        let temp = [];
        for (let i = 0; i < Arr.length; i++) {
            let itemId = Arr[i][options.parentName];
            if (parseInt(itemId) === parseInt(options.parent_id)) {
                const obj = Arr[i];
                temp = this.tableTreeIteration(Arr, {parent_id:Arr[i][options.idName], parentName:options.parentName, idName: options.idName});
                if (temp.length > 0) {
                    obj.children = temp
                }
                result.push(obj)
            }
        }
        return result;
    }

    public tableStrTreeIteration = (Arr, options) => {
        let result:any = [];
        let temp = [];
        for (let i = 0; i < Arr.length; i++) {
            let itemId = Arr[i][options.parentName];
            if (itemId === options.parentId) {
                const obj = Arr[i];
                temp = this.tableStrTreeIteration(Arr, {parentId:Arr[i][options.idName], parentName:options.parentName, idName: options.idName});
                if (temp.length > 0) {
                    obj.children = temp
                }
                result.push(obj)
            }
        }
        return result;
    }


    public getTree(id, result: any[]): any {
        const items:any = [];
        for (const obj of result) {
            if (obj !== undefined && obj.parentId === id) {
                items.push(obj);
                if (items.length !== 0) {
                    const tree = this.getTree(obj.id, result);
                    if (tree.length > 0) {
                        obj.children = tree;
                    }
                }
            }
        }
        return items;
    }

    public getOrganogramTree = (result: any[], id:any=null, isAddPid:any=true) => {
        let items:any = [];
        for (const obj of result) {
            if (obj !== undefined && obj.parent_id === id) {
                items.push(obj);
                if (items.length !== 0) {
                    const tree = this.getOrganogramTree(result, obj.id, false);
                    if (tree.length > 0) {
                        obj.children = tree;
                    }
                }
            }
        }

        if (isAddPid && id) {
            let parentItems:any = [];
            const parentObj = result.find((item) => item.id == id);
            if (parentObj) {
                parentObj.children = items;
                parentItems.push(parentObj);
            }
            return parentItems;
        }

        return items;
    }

    public convertFlatToTree(flatArray: any[]): any {
        let result: any[] = []
        if (!Array.isArray(flatArray)) {
            return result
        }

        flatArray.forEach(item => {
            delete item.children;
        });

        let map = {};
        flatArray.forEach(item => {
            map[item.id] = item;
        });

        flatArray.forEach(item => {
            let parent = map[item.parent_id];
            if (parent) {
                (parent.children || (parent.children = [])).push(item);
            }
            else {
                result.push(item);
            }
        });
        return result;
    }

    public convertTreeToFlat(treeList: any[]): any {
        let flatList: any[] = [];

        treeList.forEach(item => {
            if (item.children) {
                flatList.push({ ...item });
                flatList = [...flatList, ...this.convertTreeToFlat(item.children)];
            }
            else {
                flatList.push({ ...item });
            }
        });

        return  flatList.map(({children, ...rest}) => {
            return rest;
        });
    }

    public findById(treeList, findId): any {
        const item = treeList.find(x => x.id == findId);
        return item ? item : null;
    }

    public getParentId(treeList, findId): any {
        const item = treeList.find(x => x.id == findId);
        return item ? item.parent_id : null;
    }

    public getParentIds(flatArray: any[], findId, isAddCid:boolean = true): any {
        let parentIds: any[] = [];

        const parentId = this.getParentId(flatArray, findId);
        if (parentId) {
            parentIds.push(parentId);
            parentIds.push(...this.getParentIds(flatArray, parentId))
        }

        if (isAddCid) {
            parentIds.push(findId);
        }

        return parentIds;
    }

    public getChildIdList(treeList, parentId): any {
        return treeList.filter(x => x.parent_id == parentId)?.map(item => item.id);
    }

    public getChildIDs(flatArray: any[], parentId, isAddPid:boolean = true): any {
        let childIds: any[] = [];

        const childIdList = this.getChildIdList(flatArray, parentId);
        if (childIdList) {
            childIdList.forEach(childId => {
                childIds.push(childId);
                childIds.push(...this.getChildIDs(flatArray, childId, false))
            });
        }

        if (isAddPid) {
            childIds.push(parentId);
        }

        return childIds;
    }

    public hasChildren(treeList): any {
        return Array.isArray(treeList.children) && treeList.children.length > 0;
    }

}
