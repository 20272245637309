import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "@/constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/payment-history`
const endpoints = {
    getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
    checkPaymentConfirmation: () => `${RESOURCE_ENDPOINT}/check-payment-confirmation`,
}

export default class PaymentApi {

    public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByWhere();
        return HttpService.get(url, params, headers);
    }

    public checkPaymentConfirmation = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.checkPaymentConfirmation();
        return HttpService.post(url, payload, params, headers);
    };

}