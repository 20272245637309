import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/import`
const endpoints = {
    importExample: () => `${RESOURCE_ENDPOINT}/example`,
    importSynopsisOfResearchProject: () => `${RESOURCE_ENDPOINT}/synopsis-of-research-project`,
}

export default class ImportEticketApi {
    public importExample = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importExample();
        return HttpService.post(url, payload, params, headers);
    }
    public importSynopsisOfResearchProject = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importSynopsisOfResearchProject();
        return HttpService.post(url, payload, params, headers);
    }
}

