import { ProgramScheduleApi } from '@/api';
import { DateTimeUtils, Message } from '@/utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {useErrorHandler} from "@/hooks/useErrorHandler";

export const useScheduleProgram = () => {

    const [loadingScheduleProgram, setLoadingScheduleProgram] = useState(false)
    const [scheduleProgram, setScheduleProgram] = useState<any>({ planet_shows: [], other_shows: [] })
    const { handleErrorMessage } = useErrorHandler();

    const getScheduleProgram = (date, organizationId): Promise<any> => {
        return new Promise((resolve, reject) => {
            setLoadingScheduleProgram(true);
            const payload = {
                '$program_date': date,
                '$organization_id': organizationId,
                '$filter': `program_date eq '${date}' AND organization_id eq '${organizationId}'`
            };
            ProgramScheduleApi.getScheduleProgram(payload)
                .then((res) => {
                    setScheduleProgram(res.data.data);
                    setLoadingScheduleProgram(false);
                })
                .catch((err) => {
                    handleErrorMessage(err);
                    setLoadingScheduleProgram(false);
                    reject(err);
                });
        });
    };


    const isProgramAvailable = (date, programEndTime) => {

        const currentTime = moment().format('h:mma');
        const endTime = DateTimeUtils.timeConvertAMPM(programEndTime).replace(/\s/g, '').toLowerCase();

        const momentBeginTime = moment(currentTime, 'hh:mm A');
        const momentProgramTime = moment(endTime, 'hh:mm A');

        if (moment().isSame(moment(date), "day")) {
            if (momentBeginTime.isAfter(momentProgramTime)) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    const isCounterProgramAvailable = (date, programStartTime, programEndTime, hallId) => {
        const currentTime = moment();
        const startTime = moment(programStartTime, 'hh:mm A');
        const endTime = moment(programEndTime, 'hh:mm A');
    
        // If the program belongs to a hall and current time is more than 10 minutes
        // past the program start time, hide the program
        if (hallId && currentTime.isAfter(startTime.add(10, 'minutes'))) {
            return false;
        }
    
        // If the current time is after the program end time, hide the program
        if (currentTime.isAfter(endTime)) {
            return false;
        }
    
        // If the date is not today, hide the program
        if (!moment().isSame(moment(date), "day")) {
            return false;
        }
    
        return true;
    }

    return {
        loadingScheduleProgram,
        getScheduleProgram, //📕 Note: date must this format "2023-03-17" use moment format where you call this func moment(date).format("YYYY-MM-DD")
        scheduleProgram, //📕 Note: you can't get data from this state unless you don't call "getScheduleProgram(date)" this function with date
        isProgramAvailable,
        isCounterProgramAvailable
    };
};
