import {Message} from "./index";

export default class ErrorUtils {

    public handleNetworkError = (error:any) => {
        Message.error('A network error occurred. Please try again later.');
    }

    public handleNetworkErrorCreate = (error:any) => {
        Message.error('A network error occurred. Please try again later.');
    }

    public handleNetworkErrorUpdate = (error:any) => {
        Message.error('A network error occurred. Please try again later.');
    }

    public handleNetworkErrorDelete = (error:any) => {
        Message.error('A network error occurred. Please try again later.');
    }

    public handleNetworkErrorBulk = (error:any) => {
        Message.error('A network error occurred. Please try again later.');
    }
}
