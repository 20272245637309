import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "@/constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/payment`
const endpoints = {
    getPaymentGatewayList: () => `${RESOURCE_ENDPOINT}/payment-gateway-list`,
    ekpayPayment: () => `${RESOURCE_ENDPOINT}/ekpay-payment`,
    payOrderPayment: () => `${RESOURCE_ENDPOINT}/pay-order-payment`,
    bankDepositPayment: () => `${RESOURCE_ENDPOINT}/bank-deposit-payment`,
    cashPayment: () => `${RESOURCE_ENDPOINT}/cash-payment`,
    challanPayment: () => `${RESOURCE_ENDPOINT}/challan-payment`,
}

export default class PaymentApi {
    public getPaymentGatewayList = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getPaymentGatewayList();
        return HttpService.post(url, payload, params, headers);
    }

    public ekpayPayment = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.ekpayPayment();
        return HttpService.post(url, payload, params, headers);
    }

    public payOrderPayment = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.payOrderPayment();
        return HttpService.post(url, payload, params, headers);
    }

    public bankDepositPayment = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.bankDepositPayment();
        return HttpService.post(url, payload, params, headers);
    }

    public cashPayment = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.cashPayment();
        return HttpService.post(url, payload, params, headers);
    }

    public challanPayment = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.challanPayment();
        return HttpService.post(url, payload, params, headers);
    }
}