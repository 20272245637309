import { useScheduleProgram } from '@/hooks/lists/useScheduleProgram';
import moment from 'moment';
import React, { createContext, useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { VisitorContext } from './VisitorContext';

const initialState = {
    sidebarVisible: true,
    activeMenuKey: null,
    headerTabGroup: null,
    selectedDate: moment().format("YYYY-MM-DD"),
    scheduleDates: [moment().format("YYYY-MM-DD")]
};

export const DataContext = createContext<any>(initialState);

export const DataContextProvider = ({ children }) => {


    const { getScheduleProgram } = useScheduleProgram()
    const { serviceOrganizationId } = useContext(VisitorContext);

    const [sidebarVisible, changeSidebarVisible] = useState<boolean>(initialState.sidebarVisible);
    const [activeMenuKey, changeActiveMenuKey] = useState<any>(initialState.activeMenuKey);
    const [headerTabGroup, changeHeaderTabGroup] = useState<any>(initialState.headerTabGroup);
    const [selectedDate, setSelectedDate] = useState<any>(initialState.selectedDate);
    const [scheduleDates, setScheduleDates] = useState<any>(initialState.scheduleDates);

    useEffect(() => {
        loadSelectedDate();

        const today = moment();
        const dates: any = [];

        for (let i = 0; i < 3; i++) {
            const nextDate = today.clone().add(i, 'days').format('YYYY-MM-DD');
            dates.push(nextDate);
        }

        setScheduleDates(dates);
    }, []);

    useEffect(() => {
        localStorage.setItem('selectedDate', JSON.stringify(selectedDate));
    }, [selectedDate]);

    useEffect(() => {
        if (!!selectedDate && !!serviceOrganizationId)
        getScheduleProgram(selectedDate, serviceOrganizationId)
    }, [selectedDate, serviceOrganizationId]);

    const loadSelectedDate = () => {

        const getDate: any = localStorage.getItem('selectedDate');
        const parseDate = moment(JSON.parse(getDate)).format("YYYY-MM-DD")

        if (parseDate === "Invalid date") {
            setSelectedDate(moment().format("YYYY-MM-DD"));
        } else if (moment(parseDate).isBefore(moment())) {
            setSelectedDate(moment().format("YYYY-MM-DD"));
        } else {
            if (!!parseDate) {
                setSelectedDate(parseDate);
            }
        }

    };

    const setHeaderTabGroup = (tabGroup) => {
        changeHeaderTabGroup(tabGroup);
    };

    const setActiveMenuKey = (key) => {
        changeActiveMenuKey(key);
    };

    const setSidebarVisible = (status: any = null) => {
        if (status) {
            changeSidebarVisible(status);
        }
        else {
            changeSidebarVisible(!sidebarVisible);
        }
    };

    const contextValue = {
        sidebarVisible, activeMenuKey, headerTabGroup,
        setHeaderTabGroup, setActiveMenuKey, setSidebarVisible,
        scheduleDates, selectedDate, setSelectedDate
    };

    return (
        <DataContext.Provider value={contextValue}>
            {children}
        </DataContext.Provider>
    );
};

export default DataContextProvider;
