import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/workflow-transition-assignment`
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    assignRecordIds: () => `${RESOURCE_ENDPOINT}`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`
}

export default class WorkflowTransitionAssignmentApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    }

    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    }

    public assignRecordIds = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.assignRecordIds();
        return HttpService.post(url, payload, params, headers);
    }
}