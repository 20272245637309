// CDN Server
import _FileApi from "./Cdn/File.api";
import _CsvApi from "./Cdn/Csv.api";
import _ExcelApi from "./Cdn/Excel.api";

// Auth Server
import _GroupApi from "./Auth/Group.api";
import _OauthApi from "./Auth/Oauth.api";
import _PermissionApi from "./Auth/Permission.api";
import _ResourceApi from "./Auth/Resource.api";
import _RoleApi from "./Auth/Role.api";
import _ScopeApi from "./Auth/Scope.api";
import _UserApi from "./Auth/User.api";
import _WorkspaceApi from "./Auth/Workspace.api";
import _OrganizationApi from "./Auth/Organization.api";
import _OrganogramApi from "./Auth/Organogram.api";
import _EmployeeApi from "./Auth/Employee.api";
import _RequestAccessApi from "./Auth/RequestAccess.api";
import _ApplicantProfileApi from "./Auth/ApplicantProfile.api";
import _InstituteHeadApi from "./Auth/InstituteHead.api";

// Core Server
import _NotificationApi from "./Core/Notification.api";
import _LookupApi from "./Core/Lookup.api";
import _LocationApi from "./Core/Location.api";
import _PostApi from "./Core/Post.api";
import _OptionApi from "./Core/Option.api";
import _TemplateApi from "./Core/Template.api";
import _HelpApi from "./Core/Help.api";
import _ExportScmApi from "./Core/ExportScm.api";
import _ImportScmApi from "./Core/ImportScm.api";
import _FackApi from "./Core/Fack.api";
import _OrganizationCategoryApi from "./Core/OrganizationCategory.api";
import _DepartmentApi from "./Core/Department.api";
import _ApiResourceApi from "./Core/ApiResource.api";
import _FinancialYearApi from "./Core/FinancialYear.api";
import _EnumApi from "./Core/Enum.api";
import _BankApi from "./Core/Bank.api";
import _BankBranchApi from "./Core/BankBranch.api";
import _DesignationApi from "./Core/Designation.api";
import _DepartmentSubjectApi from "./Core/DepartmentSubject.api";
import _FacultySchoolApi from "./Core/FacultySchool.api";
import _InstituteOrganizationApi from "./Core/InstituteOrganization.api";
import _AutomationRuleApi from "./Core/AutomationRule.api";
import _WorkflowApprovalApi from "./Core/WorkflowApproval.api";
import _WorkflowStepApi from "./Core/WorkflowStep.api";
import _WorkflowTransitionApi from "./Core/WorkflowTransition.api";
import _WorkflowBulkTransitionApi from "./Core/WorkflowBulkTransition.api";
import _WorkflowTransitionAssignmentApi from "./Core/WorkflowTransitionAssignment.api";
import _ServiceMappingApi from "./Core/ServiceMapping.api";
import _ComponentApi from "./Core/Component.api";
import _ApplicantProfileEducationalQualificationApi from "./Core/ApplicantProfileEducationalQualification.api";
import _ApplicantProfilePreviousExperienceApi from "./Core/ApplicantProfilePreviousExperience.api";
import _ApplicantProfileAwardsScholarshipApi from "./Core/ApplicantProfileAwardsScholarship.api";
import _ApplicantProfilePublicationApi from "./Core/ApplicantProfilePublication.api";
import _ApplicantProfileTrainingSeminarCourseApi from "./Core/ApplicantProfileTrainingSeminarCourse.api";
import _ExamApi from "./Core/Exam.api";
import _AttachmentTypeApi from "./Core/AttachmentType.api";
import _PaymentHistoryApi from "./Core/PaymentHistory.api";

// Eticket
import _ShowExhibitTypeApi from "./Eticket/ShowExhibitType.api";
import _ShowExhibitNameApi from "./Eticket/ShowExhibitName.api";
import _GiftTypeApi from "./Eticket/GiftType.api";
import _ReviewTypeApi from "./Eticket/ReviewType.api";
import _GalleryCategoryApi from "./Eticket/GalleryCategory.api";
import _GiftEligibilitySetupApi from "./Eticket/GiftEligibilitySetup.api";
import _ExportEticketApi from "./Eticket/ExportEticket.api";
import _ImportEticketApi from "./Eticket/ImportEticket.api";
import _ProgramApi from "./Eticket/Program.api";
import _ProgramTypeApi from "./Eticket/ProgramType.api";
import _SpecialDayApi from "./Eticket/SpecialDay.api";
import _HolyDayApi from "./Eticket/HolyDay.api";
import _RearrangeSeatPlanApi from "./Eticket/RearrangeSeatPlan.api";
import _SeatPlanApi from "./Eticket/SeatPlan.api";
import _SeatLayoutApi from "./Eticket/SeatLayout.api";
import _HallSetupApi from "./Eticket/HallSetup.api";
import _ProgramScheduleApi from "./Eticket/ProgramSchedule.api";
import _TicketRequestApi from "./Eticket/TicketRequest.api";
import _RegularTicketRateApi from "./Eticket/RegularTicketRate.api";
import _BulkTicketDiscountApi from "./Eticket/BulkTicketDiscount.api";
import _SpecialDayTicketRateApi from "./Eticket/SpecialdayTicketRate.api";
import _PackageApi from "./Eticket/Package.api";
import _PackageItemApi from "./Eticket/packageItem.api";
import _CounterApi from "./Eticket/Counter.api";
import _CounterSalesApi from "./Eticket/CounterSales.api";
import _ProgramEventApi from "./Eticket/ProgramEvent.api";
import _SaleSeatsApi from "./Eticket/SaleSeatsApi.api";
import _DepositApi from "./Eticket/Deposit.api";
import _AssignCounterApi from "./Eticket/AssignCounter.api";
import _CheckoutLogApi from "./Eticket/CheckoutLog.api";
import _TicketSaleApi from "./Eticket/TicketSale.api";
import _ReserveSeatsApi from "./Eticket/ReserveSeats.api";
import _ReportEticketApi from "./Eticket/ReportEticket.api";
import _PaymentApi from "./Core/Payment.api";
import _AdminDashboardApi from "./Eticket/AdminDashboard.api";
import _ScienceBusApi from "./Eticket/ScienceBus.api";
import _FreeTransportApi from "./Eticket/FreeTransport.api";

// CMS Server
import _PageApi from "./Cms/Page.api";
import _BlocksApi from "./Cms/Blocks.api";
import _MenuApi from "./Cms/Menu.api";
import _ServicesApi from "./Cms/Services.api";
import _MediaApi from "./Cms/Media.api";
import _CategoriesApi from "./Cms/Categories.api";

import _ActivityLogApi from "./Util/ActivityLog.api";
import _AuditLogApi from "./Util/AuditLog.api";
import _WebsocketApi from "./Util/Websocket.api";

// CND Server
export const FileApi = new _FileApi();
export const CsvApi = new _CsvApi();
export const ExcelApi = new _ExcelApi();

// Auth Server
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const PermissionApi = new _PermissionApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const WorkspaceApi = new _WorkspaceApi();
export const OrganizationApi = new _OrganizationApi();
export const ServiceApi = new _ServicesApi();
export const OrganogramApi = new _OrganogramApi();
export const EmployeeApi = new _EmployeeApi();
export const RequestAccessApi = new _RequestAccessApi();
export const ApplicantProfileApi = new _ApplicantProfileApi();
export const InstituteHeadApi = new _InstituteHeadApi();

// Core Server
export const FackApi = new _FackApi();
export const NotificationApi = new _NotificationApi();
export const LookupApi = new _LookupApi();
export const LocationApi = new _LocationApi();
export const PostApi = new _PostApi();
export const OptionApi = new _OptionApi();
export const TemplateApi = new _TemplateApi();
export const HelpApi = new _HelpApi();
export const ExportScmApi = new _ExportScmApi();
export const ImportScmApi = new _ImportScmApi();
export const OrganizationCategoryApi = new _OrganizationCategoryApi();
export const DepartmentApi = new _DepartmentApi();
export const ApiResourceApi = new _ApiResourceApi();
export const FinancialYearApi = new _FinancialYearApi();
export const EnumApi = new _EnumApi();
export const BankApi = new _BankApi();
export const BankBranchApi = new _BankBranchApi();
export const DesignationApi = new _DesignationApi();
export const DepartmentSubjectApi = new _DepartmentSubjectApi();
export const FacultySchoolApi = new _FacultySchoolApi();
export const InstituteOrganizationApi = new _InstituteOrganizationApi();
export const AutomationRuleApi = new _AutomationRuleApi();
export const WorkflowApprovalApi = new _WorkflowApprovalApi();
export const WorkflowStepApi = new _WorkflowStepApi();
export const WorkflowTransitionApi = new _WorkflowTransitionApi();
export const WorkflowBulkTransitionApi = new _WorkflowBulkTransitionApi();
export const WorkflowTransitionAssignmentApi = new _WorkflowTransitionAssignmentApi();
export const ServiceMappingApi = new _ServiceMappingApi();
export const ComponentApi = new _ComponentApi();
export const ApplicantProfileEducationalQualificationApi = new _ApplicantProfileEducationalQualificationApi();
export const ApplicantProfilePreviousExperienceApi = new _ApplicantProfilePreviousExperienceApi();
export const ApplicantProfileAwardsScholarshipApi = new _ApplicantProfileAwardsScholarshipApi();
export const ApplicantProfilePublicationApi = new _ApplicantProfilePublicationApi();
export const ApplicantProfileTrainingSeminarCourseApi = new _ApplicantProfileTrainingSeminarCourseApi();
export const ExamApi = new _ExamApi();
export const AttachmentTypeApi = new _AttachmentTypeApi();
export const PaymentHistoryApi = new _PaymentHistoryApi();

// CMS Server
export const PageApi = new _PageApi();
export const BlocksApi = new _BlocksApi();
export const MenuApi = new _MenuApi();
export const ServicesApi = new _ServicesApi();
export const MediaApi = new _MediaApi();
export const CategoriesApi = new _CategoriesApi();

// Util
export const ActivityLogApi = new _ActivityLogApi();
export const AuditLogApi = new _AuditLogApi();
export const WebsocketApi = new _WebsocketApi();

// Eticket Server
export const ShowExhibitTypeApi = new _ShowExhibitTypeApi();
export const ShowExhibitNameApi = new _ShowExhibitNameApi();
export const ReviewTypeApi = new _ReviewTypeApi();
export const GalleryCategoryApi = new _GalleryCategoryApi();
export const GiftTypeApi = new _GiftTypeApi();
export const GiftEligibilitySetupApi = new _GiftEligibilitySetupApi();
export const ExportEticketApi = new _ExportEticketApi();
export const ImportEticketApi = new _ImportEticketApi();
export const ProgramApi = new _ProgramApi();
export const ProgramTypeApi = new _ProgramTypeApi();
export const SpecialDayApi = new _SpecialDayApi();
export const HolyDayApi = new _HolyDayApi();
export const RearrangeSeatPlanApi = new _RearrangeSeatPlanApi();
export const SeatPlanApi = new _SeatPlanApi();
export const SeatLayoutApi = new _SeatLayoutApi();
export const HallSetupApi = new _HallSetupApi();
export const ProgramScheduleApi = new _ProgramScheduleApi();
export const TicketRequestApi = new _TicketRequestApi();
export const RegularTicketRateApi = new _RegularTicketRateApi();
export const BulkTicketDiscountApi = new _BulkTicketDiscountApi();
export const SpecialDayTicketRateApi = new _SpecialDayTicketRateApi();
export const PackageApi = new _PackageApi();
export const PackageItemApi = new _PackageItemApi();
export const CounterApi = new _CounterApi();
export const DepositApi = new _DepositApi();
export const AssignCounterApi = new _AssignCounterApi();
export const CheckoutLogApi = new _CheckoutLogApi();
export const CounterSalesApi = new _CounterSalesApi();
export const ProgramEventApi = new _ProgramEventApi();
export const SaleSeatsApi = new _SaleSeatsApi();
export const TicketSaleApi = new _TicketSaleApi();
export const ReserveSeatsApi = new _ReserveSeatsApi();
export const ReportEticketApi = new _ReportEticketApi();
export const PaymentApi = new _PaymentApi();
export const AdminDashboardApi = new _AdminDashboardApi();
export const ScienceBusApi = new _ScienceBusApi();
export const FreeTransportApi = new _FreeTransportApi();
